import { certification_info } from "API";
import { appSyncRequestMutation, appSyncRequestQuery } from "assets/js/request";
import { AssociateResponse, DetailsMessage } from "assets/js/type";
import Alert from "components/Alert";
import { AlertType } from "components/Alert/alert";
import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import FormItem from "components/FormItem";
import HeaderPanel from "components/HeaderPanel";
import MultiSelect from "components/MultiSelect";
import PagePanel from "components/PagePanel";
import TextArea from "components/TextArea";
import TextInput from "components/TextInput";
import Tiles from "components/Tiles";
import { associateCert } from "graphql/mutations";
import { listDistribution } from "graphql/queries";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const AssociateCert: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { certs }: { certs: certification_info[] } = location.state || {
    certs: [],
  };
  const [createSnapShot, setCreateSnapShot] = useState("true");
  const [loadingDistribution, setLoadingDistribution] = useState(false);
  const [distributionList, setDistributionList] = useState<any[]>([]);
  const [selectedDistribution, setSelectedDistribution] = useState<string[]>(
    []
  );
  const [snapshotName, setSnapshotName] = useState("");
  const [snapshotDescription, setSnapshotDescription] = useState("");

  const [associating, setAssociating] = useState(false);

  const [distributionError, setDistributionError] = useState("");
  const [snapshotNameError, setSnapshotNameError] = useState("");
  const [errorMessage, setErrorMessage] = useState<AssociateResponse | null>();
  const [detailsMessage, setDetailsMessage] = useState<DetailsMessage | null>();

  const { t } = useTranslation();
  const BreadCrunbList = [
    {
      name: t("name"),
      link: "/",
    },
    {
      name: t("ssl:sslList"),
      link: "/config/certification/list",
    },
    {
      name: t("ssl:associate.associateDistribution"),
    },
  ];

  if (certs.length <= 0) {
    navigate("/config/certification/list");
  }

  // Get Snapshot List By Distribution
  const getDistributionList = async () => {
    try {
      setDistributionList([]);
      setLoadingDistribution(true);
      const resData = await appSyncRequestQuery(listDistribution);
      const Cloudfront_info_list: any[] = resData.data.listDistribution;
      const tmpList = [];
      for (const cfdistlistKey in Cloudfront_info_list) {
        const cname =
          Cloudfront_info_list[cfdistlistKey].aliases.Quantity === 0
            ? ""
            : Cloudfront_info_list[cfdistlistKey].aliases.Items[0];
        tmpList.push({
          name: Cloudfront_info_list[cfdistlistKey].id + " | " + cname,
          value: Cloudfront_info_list[cfdistlistKey].id,
        });
      }
      setLoadingDistribution(false);
      setDistributionList(tmpList);
    } catch (error) {
      console.error(error);
    }
  };

  // Associate Certificate to Distribution
  const associateCertToDistribution = async () => {
    setErrorMessage(null);
    if (selectedDistribution.length <= 0) {
      setDistributionError(t("ssl:associate.distributionRequired"));
      return;
    } else {
      setDistributionError("");
    }

    if (selectedDistribution.length > 50) {
      setDistributionError(t("ssl:associate.maxDistributionTips"));
      return;
    } else {
      setDistributionError("");
    }

    if (createSnapShot === "true" && snapshotName.trim() === "") {
      setSnapshotNameError(t("ssl:associate.snapshotNameRequired"));
      return;
    } else {
      setSnapshotNameError("");
    }

    // Start Job
    setAssociating(true);
    try {
      const resData = await appSyncRequestMutation(associateCert, {
        cf_id_list: selectedDistribution,
        cert_arn: certs[0].CertificateArn,
        create_snapshot: createSnapShot,
        snapshot_name: snapshotName,
        snapshot_desc: snapshotDescription,
      });
      console.info("resData.data.associateCert:", resData.data.associateCert);
      const associateRes: AssociateResponse = JSON.parse(
        resData.data.associateCert
      );
      console.info("associateRes:", associateRes);
      if (associateRes.status === "SUCCESS") {
        setErrorMessage(null);
        navigate("/config/certification/jobs");
      } else {
        try {
          const detailsObject = JSON.parse(associateRes.details.toString());
          setDetailsMessage(detailsObject);
        } catch (error) {
          setDetailsMessage(null);
        }
        setErrorMessage(associateRes);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAssociating(false);
    }
  };

  useEffect(() => {
    getDistributionList();
  }, []);

  return (
    <div>
      <Breadcrumb list={BreadCrunbList} />
      <div className="m-w-800">
        <PagePanel title="">
          <HeaderPanel title={t("ssl:associate.associateDistribution")}>
            <FormItem
              optionTitle={t("ssl:associate.selectCert")}
              optionDesc={t("ssl:associate.selectCertDesc")}
            >
              <TextArea
                readonly
                rows={2}
                value={`CertificateArn: ${certs[0].CertificateArn}\nCNAME: ${certs[0].DomainName}`}
              ></TextArea>
            </FormItem>
            <FormItem
              optionTitle={t("ssl:associate.targetDistribution")}
              optionDesc={t("ssl:associate.targetDistributionDesc")}
              errorText={distributionError}
            >
              <MultiSelect
                // hasFilter
                loading={loadingDistribution}
                placeholder={t("ssl:associate.targetDistributionPlaceholder")}
                optionList={distributionList}
                onChange={(items) => {
                  setErrorMessage(null);
                  setDistributionError("");
                  setSelectedDistribution(items);
                }}
                value={selectedDistribution}
              />
            </FormItem>
            <FormItem
              optionTitle={t("ssl:associate.createSnapshot")}
              optionDesc=""
            >
              <Tiles
                name="autoCreate"
                value={createSnapShot}
                onChange={(event) => {
                  setSnapshotNameError("");
                  setCreateSnapShot(event.target.value);
                }}
                items={[
                  {
                    label: t("ssl:associate.createSnapshot"),
                    description: t("ssl:associate.createSnapshotDesc"),
                    value: "true",
                  },
                  {
                    label: t("ssl:associate.notCreateSnapshot"),
                    description: t("ssl:associate.notCreateSnapshotDesc"),
                    value: "false",
                  },
                ]}
              />
            </FormItem>

            {createSnapShot === "true" ? (
              <>
                <FormItem
                  optionTitle={t("ssl:associate.snapshotName")}
                  optionDesc={t("ssl:associate.snapshotNameDesc")}
                  errorText={snapshotNameError}
                >
                  <div>
                    <TextInput
                      placeholder="cert-update-20240606"
                      value={snapshotName}
                      onChange={(e) => {
                        setSnapshotNameError("");
                        setSnapshotName(e.target.value);
                      }}
                    />
                  </div>
                </FormItem>
                <FormItem
                  optionTitle={t("ssl:associate.snapshotDescription")}
                  optionDesc=""
                >
                  <div>
                    <TextInput
                      placeholder={t(
                        "ssl:associate.snapshotDescriptionPlaceHolder"
                      )}
                      value={snapshotDescription}
                      onChange={(e) => {
                        setSnapshotDescription(e.target.value);
                      }}
                    />
                  </div>
                </FormItem>
              </>
            ) : (
              <></>
            )}

            {errorMessage ? (
              <Alert
                type={AlertType.Error}
                title={t("ssl:associate.validationFailed")}
                content={
                  <div>
                    {errorMessage?.message}
                    <div>
                      {detailsMessage &&
                        Object.entries(detailsMessage).map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value}
                          </div>
                        ))}
                    </div>
                  </div>
                }
              />
            ) : (
              <></>
            )}
          </HeaderPanel>

          <div className="button-action text-right">
            <Button
              btnType="text"
              onClick={() => navigate("/config/certification/list")}
            >
              {t("button.cancel")}
            </Button>
            <Button
              loading={associating}
              btnType="primary"
              onClick={() => {
                associateCertToDistribution();
              }}
            >
              {t("button.startJob")}
            </Button>
          </div>
        </PagePanel>
      </div>
    </div>
  );
};

export default AssociateCert;
