import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import HeaderPanel from "components/HeaderPanel";
import { useNavigate, useParams } from "react-router-dom";
import ValueWithLabel from "components/ValueWithLabel";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useDispatch } from "react-redux";
import { ActionType } from "reducer/appReducer";
import { StatusType } from "./StatusItem";
import { appSyncRequestQuery } from "../../../assets/js/request";
import { getJobInfo } from "../../../graphql/queries";
import { SSLJob } from "../../../API";
import Modal from "../../../components/Modal";
import { SelectType, TablePanel } from "../../../components/TablePanel";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ProgressBar from "components/ProgressBar";
import Status from "components/Status/Status";
import { Spinner } from "@cloudscape-design/components";

const StatusTextMap: any = {
  SUCCESS: "ssl:status.success",
  NOTSTART: "ssl:status.notStartd",
  INPROGRESS: "ssl:status.inProgress",
  COMPLETED: "ssl:status.success",
  FAILED: "ssl:status.failed",
  NONEED: "ssl:status.noneed",
};

type CFItem = {
  id: string;
  status: string;
};

const JobAssociateDetail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const [jobInfo, setJobInfo] = useState<SSLJob>({
    __typename: "SSLJob",
    jobId: jobId || "",
    cert_completed_number: 0,
    cert_total_number: 0,
    cloudfront_distribution_created_number: 0,
    cloudfront_distribution_total_number: 0,
    job_input: "",
    certCreateStageStatus: "INPROGRESS",
    certValidationStageStatus: "NOTSTART",
    creationDate: "2022-01-01",
    distStageStatus: "NOTSTART",
    jobType: "create",
    promptInfo: "",
    dcv_validation_msg: "",
    associateStageStatus: "INPROGRESS",
  });
  const [loadingData, setLoadingData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openCloudfrontModal, setOpenCloudfrontModal] = useState(false);
  const [cloudFrontItems, setCloudFrontItems] = useState<CFItem[]>([]);
  const { t } = useTranslation();
  const BreadCrunbList = [
    {
      name: t("name"),
      link: "/",
    },
    {
      name: t("ssl:certJobList"),
      link: "/config/certification/jobs",
    },
    {
      name: jobId || "",
    },
  ];

  useEffect(() => {
    dispatch({ type: ActionType.CLOSE_SIDE_MENU });
  }, []);

  // Get Version List By Distribution
  const fetchJobInfo = async (isRefresh = false) => {
    try {
      if (!isRefresh) {
        setLoadingData(true);
      }
      const resData = await appSyncRequestQuery(getJobInfo, {
        jobId: jobId,
      });
      const tmpCFList: CFItem[] = [];
      const jobInfoNew: SSLJob = resData.data.getJobInfo;
      if (jobInfoNew.complete_list) {
        jobInfoNew.complete_list.map((item) => {
          tmpCFList.push({
            id: item ?? "",
            status: "COMPLETED",
          });
        });
      }
      if (jobInfoNew.in_progress_list) {
        jobInfoNew.in_progress_list.map((item) => {
          tmpCFList.push({
            id: item ?? "",
            status: "INPROGRESS",
          });
        });
      }
      setJobInfo(jobInfoNew);
      setCloudFrontItems(tmpCFList);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchJobInfo();
    const refreshInterval = setInterval(() => {
      fetchJobInfo(true);
    }, 20000);
    return () => clearInterval(refreshInterval);
  }, []);

  const constructCloudfrontLink = (arn: string) => {
    const tmpArr = arn.split("/");
    return tmpArr[1];
  };

  const constructCertificateConsoleLink = (arn: string) => {
    const tmpArr = arn.split(":");
    const region = tmpArr[3];
    const tmp = arn.split("/");
    const certId = tmp[1];
    return (
      "https://us-east-1.console.aws.amazon.com/acm/home?region=" +
      region +
      "#/certificates/" +
      certId
    );
  };

  if (loadingData) {
    return <Spinner />;
  }

  return (
    <div>
      <Breadcrumb list={BreadCrunbList} />
      <div className="pb-50">
        <HeaderPanel
          title={t("ssl:jobDetail.jobOverview")}
          desc={jobInfo?.jobType?.toString()}
          action={
            <div>
              <Button
                btnType="primary"
                onClick={() => {
                  navigate("/config/certification/list");
                }}
              >
                {t("button.backToCert")}
              </Button>
              <Button
                className="ml-10"
                btnType="primary"
                onClick={() => {
                  navigate("/config/certification/jobs");
                }}
              >
                {t("button.backToJob")}
              </Button>
            </div>
          }
        >
          <div className="flex value-label-span">
            <div>
              <ValueWithLabel label={t("ssl:jobDetail.overallStatus")}>
                <div className={jobInfo.associateStageStatus ?? ""}>
                  <Status status={jobInfo.associateStageStatus ?? ""} />
                </div>
              </ValueWithLabel>
            </div>
            <div className="flex-1 border-left-c">
              <ValueWithLabel label={t("ssl:jobDetail.curExpCert")}>
                <div className="flex">
                  <div className="job-status-number">
                    {`${jobInfo?.certList?.length}/${jobInfo.cert_total_number}`}
                  </div>
                  <div className="flex-1">
                    <Button
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      {t("button.viewCertInJob")}
                      <span>
                        <OpenInNewIcon />
                      </span>
                    </Button>
                  </div>
                </div>
              </ValueWithLabel>
            </div>
            <div className="flex-1 border-left-c">
              <ValueWithLabel label={t("ssl:jobDetail.curExpCF")}>
                <div className="flex">
                  <div className="job-status-number">
                    {jobInfo.associate_completed_number +
                      "/" +
                      jobInfo.associate_total_number}
                  </div>
                  <div className="flex-1">
                    <Button
                      onClick={() => {
                        setOpenCloudfrontModal(true);
                      }}
                    >
                      {t("button.viewCFInJob")}
                      <span>
                        <OpenInNewIcon />
                      </span>
                    </Button>
                  </div>
                </div>
              </ValueWithLabel>
            </div>
          </div>
        </HeaderPanel>

        <HeaderPanel
          title={t("ssl:jobDetail.jobStatus")}
          action={
            <div>{/*<Button btnType="text">More resources</Button>*/}</div>
          }
        >
          <div className="job-status-list">
            <div className="job-status-list-item">
              <div className="job-status-list-item-title">
                {t("ssl:associate.associateTarget")}
              </div>
              <div className="job-status-list-item-content">
                <div className="job-status-list-item-content-sub-title">
                  {t("ssl:associate.automaticProcess")}
                </div>
                <div className="job-status-list-item-content-desc">
                  {t("ssl:associate.withGivenCert")}
                </div>
                <div
                  className={`job-status-list-item-content-status ${jobInfo.associateStageStatus?.toLowerCase()}`}
                >
                  <div>
                    {jobInfo.associateStageStatus === StatusType.SUCCESS && (
                      <CheckCircleOutlineIcon fontSize="small" />
                    )}
                    {jobInfo.associateStageStatus === StatusType.INPROGRESS && (
                      <AccessTimeIcon fontSize="small" />
                    )}
                    {jobInfo.associateStageStatus === StatusType.FAILED && (
                      <HighlightOffIcon fontSize="small" />
                    )}
                  </div>
                  <div className="ml-10 flex-1">
                    <div>
                      {t(StatusTextMap[jobInfo.associateStageStatus ?? ""])}
                    </div>
                    <div>
                      <div className="progress-bar flex">
                        <div className="bar flex-1">
                          <ProgressBar
                            value={
                              ((jobInfo.associate_completed_number ?? 0) /
                                (jobInfo?.associate_total_number ?? 1)) *
                              100
                            }
                          />
                        </div>
                        <div className="number">
                          {((jobInfo.associate_completed_number ?? 0) /
                            (jobInfo?.associate_total_number ?? 1)) *
                            100}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="bottom-text">
                      {jobInfo.associateStageStatus === "INPROGRESS" && (
                        <span>{t("ssl:associate.associateInProgress")}</span>
                      )}
                      {jobInfo.associateStageStatus === "SUCCESS" && (
                        <span>{t("ssl:associate.associateSuccess")}</span>
                      )}
                      {jobInfo.associateStageStatus === "FAILED" && (
                        <span>{jobInfo.associate_msg}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderPanel>
      </div>
      <Modal
        title={t("ssl:jobDetail.curExpCert")}
        isOpen={openModal}
        fullWidth={false}
        closeModal={() => {
          setOpenModal(false);
        }}
        actions={
          <div className="button-action no-pb text-right">
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t("button.ok")}
            </Button>
          </div>
        }
      >
        <div className="m-w-800">
          <TablePanel
            hideHeader
            loading={loadingData}
            title=""
            selectType={SelectType.NONE}
            actions={<div></div>}
            pagination={<div />}
            items={jobInfo.certList ?? []}
            columnDefinitions={[
              {
                id: "Arn",
                header: t("ssl:jobDetail.certArn"),
                cell: (e: string) => {
                  return (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={constructCertificateConsoleLink(e)}
                    >
                      {e}
                    </a>
                  );
                },
                // sortingField: "alt",
              },
            ]}
            changeSelected={() => {
              // console.info("select item:", item);
              // setSelectedItems(item);
              // setcnameList(MOCK_REPOSITORY_LIST);
            }}
          />
        </div>
      </Modal>
      <Modal
        title={t("ssl:jobDetail.curExpCF")}
        isOpen={openCloudfrontModal}
        fullWidth={false}
        closeModal={() => {
          setOpenCloudfrontModal(false);
        }}
        actions={
          <div className="button-action no-pb text-right">
            <Button
              onClick={() => {
                setOpenCloudfrontModal(false);
              }}
            >
              {t("button.ok")}
            </Button>
          </div>
        }
      >
        <div className="m-w-800">
          <TablePanel
            hideHeader
            // loading={loadingData}
            title=""
            selectType={SelectType.NONE}
            actions={<div></div>}
            pagination={<div />}
            items={cloudFrontItems}
            columnDefinitions={[
              {
                id: "Arn",
                header: t("ssl:jobDetail.cfId"),
                cell: (e: CFItem) => {
                  return (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://us-east-1.console.aws.amazon.com/cloudfront/v3/home#/distributions/${constructCloudfrontLink(
                        e.id
                      )}`}
                    >
                      {e.id}
                    </a>
                    // constructCloudfrontLink(e)
                  );
                },
              },
              {
                id: "Arn",
                header: t("ssl:jobDetail.status"),
                cell: (e: CFItem) => {
                  return <Status status={e.status} />;
                  // constructCloudfrontLink(e)
                },
              },
            ]}
            changeSelected={() => {
              // console.info("select item:", item);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default JobAssociateDetail;
